import bernmobil from "./bernmobil";
import ch from "./ch";
import forchbahn from "./forchbahn";
import geops from "./geops";
import io from "./io";
import rbs from "./RBS";
import rheinbahn from "./rheinbahn";
import sbb from "./SBB";
import sob from "./SOB";
import stigwb from "./STIGWB";
import trenord from "./trenord";
import vbz from "./VBZ";
import winterthur from "./winterthur";

export default {
  bernmobil,
  ch,
  forchbahn,
  geops,
  io,
  rbs,
  rheinbahn,
  sbb,
  sob,
  stigwb,
  trenord,
  vbz,
  winterthur,
};
